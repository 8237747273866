import { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { ethers } from "ethers";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import {
  setApprovedCurrency,
  setApprovedAmount,
} from "../reducers/approvalSlice";
import { getPresaleAddress } from "../utils/getAddresses";
import tokensByChainId from "../utils/tokensByChainId";
import erc20Abi from "../utils/abis/erc20Abi.json";
import { debounce } from "../utils/debounce";

const NATIVE_CURRENCIES = ["ETH", "POL", "BNB", "AVAX"];

const ERC20_ABI = erc20Abi;

const useCheckApproval = (currency, amount) => {
  const dispatch = useDispatch();
  const { walletProvider } = useWeb3ModalProvider();
  const { chainId, address } = useWeb3ModalAccount();
  const presaleAddress = getPresaleAddress(chainId);

  const checkApprovalStatus = useCallback(
    debounce(async () => {
      if (!address || !walletProvider || !currency || !amount || !chainId)
        return;

      const numericAmount =
        typeof amount === "string" ? parseFloat(amount) : Number(amount);

      if (!Number.isFinite(numericAmount)) {
        console.error("Invalid amount: Amount is not a finite number", amount);
        dispatch(setApprovedCurrency({ chainId, currency, isApproved: false }));
        dispatch(setApprovedAmount({ chainId, amount: "0" }));
        return;
      }

      try {
        if (NATIVE_CURRENCIES.includes(currency)) {
          const parsedAmount = ethers.utils
            .parseUnits(numericAmount.toString(), 18)
            .toString();
          dispatch(
            setApprovedCurrency({ chainId, currency, isApproved: true })
          );
          dispatch(
            setApprovedAmount({
              chainId,
              amount: parsedAmount,
            })
          );
          return;
        }

        const provider = new ethers.providers.Web3Provider(walletProvider);
        const tokenAddress = tokensByChainId[chainId]?.[currency]?.address;
        const spenderAddress = presaleAddress;

        if (!tokenAddress || !spenderAddress) {
          console.error(
            "Token or spender address not found for chainId:",
            chainId
          );
          return;
        }

        const decimals = tokensByChainId[chainId][currency]?.decimals;
        if (!Number.isFinite(decimals)) {
          console.error("Invalid decimals for token:", currency, decimals);
          return;
        }

        const contract = new ethers.Contract(tokenAddress, ERC20_ABI, provider);
        const allowance = await contract.allowance(address, spenderAddress);
        const requiredAmount = ethers.utils.parseUnits(
          numericAmount.toString(),
          decimals
        );

        if (ethers.BigNumber.from(allowance).gte(requiredAmount)) {
          dispatch(
            setApprovedCurrency({ chainId, currency, isApproved: true })
          );
          dispatch(
            setApprovedAmount({ chainId, amount: requiredAmount.toString() })
          );
        } else {
          dispatch(
            setApprovedCurrency({ chainId, currency, isApproved: false })
          );
          dispatch(setApprovedAmount({ chainId, amount: "0" }));
        }
      } catch (error) {
        console.error("Failed to check approval status:", error);
      }
    }, 300),
    [address, walletProvider, currency, amount, chainId, dispatch]
  );

  useEffect(() => {
    checkApprovalStatus();
  }, [address, walletProvider, currency, amount, chainId, checkApprovalStatus]);

  return checkApprovalStatus;
};

export default useCheckApproval;
