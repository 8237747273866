import React from "react";
import Header from "../layout/Header/Header";

import Footer from "../layout/Footer/Footer";
import Banner from "../components/Home/Banner/Banner.js";
import Ecosystem from "../components/Home/Ecosystem/Ecosystem.js";
import Platforms from "../components/Home/Platforms/Platforms.js";
import Tiers from "../components/Home/Tiers/Tiers.js";
import Tokenomics from "../components/Home/Tokenomics/Tokenomics.js";
import OurThesis from "../components/Home/OurThesis/OurThesis.js";
import Roadmap from "../components/Home/Roadmap/Roadmap.js";
import Eclipse from "../components/Home/Eclipse/Eclipse.js";

import Faq from "../components/Home/Faq/Faq.js";
import LaunchSale from "../components/Home/LaunchSale/LaunchSale.js";
import AuditSection from "../components/Home/AuditSecton/AuditSecton.js";
import WhyBtcoin from "../components/Home/WhyBtcoin/WhyBtcoin.js";
import Top from "../components/top";
import LaunchApp from "../components/Home/LaunchApp/LaunchApp.js";
import NovaBox from "../components/Home/NovaBox/NovaBox.js";
import usePageTitle from "../hooks/usePageTitle";

const Home = () => {
  usePageTitle(
    "Presale | Stacknova AI - The World's First AI-Powered Bitcoin Launchpad"
  );
  return (
    <div>
      <Top />
      <Header />

      <div>
        <div className="top a pt- 5">
          <Banner />
          <Ecosystem />
          <LaunchApp />
          <Eclipse />
          <OurThesis />
          <AuditSection />
          <WhyBtcoin />
          <Platforms />
          <Tiers />
          <Tokenomics />
          <Roadmap />
          <LaunchSale />
          <Faq />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
