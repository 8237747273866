import React, { useEffect } from "react";
import Intercom from "@intercom/messenger-js-sdk";
import { useSelector } from "react-redux";

export default function IntercomInitializer() {
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    const intercomSettings = {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    };

    if (user && user._id) {
      intercomSettings.user_id = user._id.toString();
      intercomSettings.created_at = user.connected_since
        ? Math.floor(new Date(user.connected_since).getTime() / 1000)
        : undefined;
    }

    Intercom(intercomSettings);
  }, [user]);

  return null;
}
