import React, { useEffect } from "react";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedCurrency,
  setSelectedFiat,
  setCurrencies,
} from "../../../../reducers/currencySlice";
import { setLastConnectedChainId } from "../../../../reducers/amountsSlice";
import { useTranslation } from "react-i18next";
import {
  getCurrencyData,
  currencyIcons,
  specialCurrencyLabels,
} from "../../../../utils/currenciesUtils";

const CurrencySelector = () => {
  const { chainId, isConnected } = useWeb3ModalAccount();
  const { t } = useTranslation();

  // Selectors
  const buyWithCard = useSelector((state) => state.buyWithCard.buyWithCard);
  const lastConnectedChainId = useSelector(
    (state) => state.amounts.lastConnectedChainId
  );
  const currencies = useSelector((state) => state.currency.currencies);

  const dispatch = useDispatch();

  // Effect to handle chainId updates and store lastConnectedChainId
  useEffect(() => {
    if (isConnected) {
      try {
        localStorage.setItem("lastConnectedChainId", chainId);
        dispatch(setLastConnectedChainId(chainId));
      } catch (error) {
        console.error(
          "[CurrencySelector] Error setting lastConnectedChainId in localStorage:",
          error
        );
        // Optionally, set a default chainId or handle the error gracefully
      }
    }
  }, [chainId, isConnected, dispatch]);

  // Effect to update currencies based on buyWithCard and chainId
  useEffect(() => {
    let feedCurrencies;
    if (buyWithCard) {
      feedCurrencies = getCurrencyData(chainId, isConnected, 137);
    } else {
      feedCurrencies = getCurrencyData(
        chainId,
        isConnected,
        lastConnectedChainId
      );
    }

    let combinedCurrencies = [];
    const includesMatic = chainId === 137 || "POL" in feedCurrencies;

    if (buyWithCard) {
      const maticCurrencies = ["USD", "GBP", "EUR"];
      combinedCurrencies = maticCurrencies.map((symbol) => ({
        symbol,
        image: currencyIcons[symbol] || currencyIcons["ETH"], // Fallback to ETH
        label: specialCurrencyLabels[symbol] || symbol,
      }));
    } else if (includesMatic && !buyWithCard) {
      const maticCurrencies = ["POL", "USDT", "USDC", "DAI"];
      combinedCurrencies = maticCurrencies.map((symbol) => ({
        symbol,
        image: currencyIcons[symbol] || currencyIcons["ETH"], // Fallback to ETH
        label: specialCurrencyLabels[symbol] || symbol,
      }));
    } else {
      combinedCurrencies = Object.keys(feedCurrencies).map((symbol) => ({
        symbol,
        image: currencyIcons[symbol] || currencyIcons["ETH"],
        label: specialCurrencyLabels[symbol] || symbol,
      }));
    }

    if (combinedCurrencies.length > 0) {
      dispatch(setCurrencies(combinedCurrencies));
    } else {
      // Fallback to default crypto currencies if combinedCurrencies is empty
      const defaultCryptoCurrencies = ["ETH", "USDT", "USDC", "DAI"];
      const fallbackCurrencies = defaultCryptoCurrencies.map((symbol) => ({
        symbol,
        image: currencyIcons[symbol] || currencyIcons["ETH"],
        label: specialCurrencyLabels[symbol] || symbol,
      }));
      dispatch(setCurrencies(fallbackCurrencies));
      // console.warn(
      //   "[CurrencySelector] combinedCurrencies is empty. Using fallback currencies:",
      //   fallbackCurrencies
      // );
    }
  }, [chainId, isConnected, lastConnectedChainId, buyWithCard, dispatch]);

  // Handler for currency click
  const handleCurrencyClick = (currency) => {
    if (
      currency.symbol === "USD" ||
      currency.symbol === "EUR" ||
      currency.symbol === "GBP"
    ) {
      dispatch(setSelectedFiat(currency.symbol));
    } else {
      dispatch(setSelectedCurrency(currency.symbol));
    }
  };

  // Optional: Handle undefined or empty currencies
  if (!currencies || currencies.length === 0) {
    // console.warn("[CurrencySelector] currencies is undefined or empty.");
    return <div>Loading currencies...</div>;
  }

  return (
    <div
      className="unconnected css-1f35gka"
      style={{ justifyContent: "start" }}
    >
      <div className="css-1p1m4ay" style={{ flexWrap: "wrap" }}>
        <p className="text-[14px] text-[#79716B] mr-[8px]">
          {t("homePage.banner.use")}
        </p>
        {currencies.map((currency, index) => (
          <React.Fragment key={currency.symbol}>
            {index !== 0 && (
              <p className="text-[14px] ml-[8px] mr-[8px] text-[#79716B]">
                {t("homePage.banner.or")}
              </p>
            )}
            <div
              className="css-70qvj9"
              onClick={() => handleCurrencyClick(currency)}
            >
              <div className="css-759u60">
                {typeof currency.image === "string" ? (
                  <img
                    src={currency.image}
                    alt={currency.symbol}
                    style={{
                      borderRadius: "50%",
                      width: "20px",
                      height: "20px",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  React.createElement(currency.image, {
                    style: {
                      width: "20px",
                      height: "20px",
                    },
                    "aria-label": currency.symbol,
                  })
                )}
                <p className="text-[14px] text-[#F5F5F4] ml-[4px]">
                  {currency.label}
                </p>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default CurrencySelector;
