// src/hooks/useReferralLink.js

import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import useStoreReferralLink from "./useStoreReferralLink";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { setReferralLink, setReferral } from "../reducers/referralSlice";
import { setReferrer, setReferrerAddress } from "../reducers/referrerSlice";
import { setNovaPoints } from "../reducers/novaPointsSlice";
import useReferrer from "./useReferrer";
import { ethers } from "ethers";

const ZERO_ADDRESS = ethers.constants.AddressZero;

const generateReferralLink = async (address) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(address);
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  const refID = `stxnova-${hashHex.substring(0, 8)}`;
  const referralLink = `${process.env.REACT_APP_REFERRAL_BASE_URL}?ref=${refID}`;
  return referralLink;
};

const fetchExistingLink = async (walletAddress, maxRetries = 3) => {
  let attempts = 0;

  while (attempts < maxRetries) {
    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_SERVER_URL
        }/referralLinkHandler?walletAddress=${encodeURIComponent(
          walletAddress
        )}`,
        {
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();

        if (!data.linkFound) {
          // console.log("Referral link not found.");
          return null;
        }

        return data.referralLink;
      } else {
        throw new Error(
          `Failed to fetch referral link: ${response.statusText}`
        );
      }
    } catch (error) {
      attempts += 1;
      console.error(`Attempt ${attempts} failed: ${error.message}`);

      if (attempts >= maxRetries) return null;
    }
  }
};

const getReferrerAddress = async (referrerId) => {
  if (!referrerId) return ZERO_ADDRESS;

  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/getReferrerAddress`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: JSON.stringify({ referrerUrl: referrerId }), // Correct key
      }
    );

    if (response.ok) {
      const data = await response.json();
      return data.referrerAddress;
    } else {
      return ZERO_ADDRESS;
    }
  } catch (error) {
    return ZERO_ADDRESS;
  }
};

// Custom hook for managing the referral link
const useReferralLink = () => {
  const dispatch = useDispatch();
  const { address, isConnected } = useWeb3ModalAccount();
  const referralLink = useSelector((state) => state.referral.referral);
  const referrerUpdateCount = useSelector(
    (state) => state.referrer.referrerUpdateCount
  );
  const [copied, setCopied] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const { referrer, isReferrerLoaded } = useReferrer();
  const { storeReferralLink } = useStoreReferralLink();
  const prevAddressRef = useRef(null);
  const isMounted = useRef(false);
  const isFetchingRef = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const manageReferralLink = async () => {
      if (!isConnected || !address || isFetchingRef.current) return;
      if (address === prevAddressRef.current && referralLink) return;

      if (!isReferrerLoaded) {
        return;
      }

      dispatch(setReferral(null));
      dispatch(setNovaPoints(null));

      isFetchingRef.current = true;
      setLoading(true);

      try {
        const existingLink = await fetchExistingLink(address);
        if (existingLink && existingLink.referral_id) {
          const fullLink = `${process.env.REACT_APP_REFERRAL_BASE_URL}?ref=${existingLink.referral_id}`;
          if (isMounted.current) {
            dispatch(setReferralLink(fullLink));
            dispatch(setReferral(existingLink.referral_id));
            dispatch(setNovaPoints(existingLink.nova_points_rewards || 0));

            if (existingLink.referrer_id) {
              dispatch(setReferrer(existingLink.referrer_id));

              const referrerAddress = await getReferrerAddress(
                existingLink.referrer_id
              );
              dispatch(setReferrerAddress(referrerAddress));
            } else if (referrer) {
              // Use referrer from URL/localStorage if backend has no referrer_id
              dispatch(setReferrer(referrer));

              const referrerAddress = await getReferrerAddress(referrer);
              dispatch(setReferrerAddress(referrerAddress));
            } else {
              dispatch(setReferrer(null));
              dispatch(setReferrerAddress(ZERO_ADDRESS));
            }
          }
        } else {
          const newLink = await generateReferralLink(address);
          const newId = newLink.split("=")[1];
          const savedLink = await storeReferralLink(address, newId, referrer);
          const fullLink = `${process.env.REACT_APP_REFERRAL_BASE_URL}?ref=${savedLink}`;
          if (isMounted.current) {
            dispatch(setReferralLink(fullLink));
            dispatch(setReferral(savedLink));

            if (referrer) {
              dispatch(setReferrer(referrer));

              const referrerAddress = await getReferrerAddress(referrer);
              dispatch(setReferrerAddress(referrerAddress));
            } else {
              dispatch(setReferrer(null));
              dispatch(setReferrerAddress(ZERO_ADDRESS));
            }
          }
        }
      } catch (error) {
        if (isMounted.current) setError(error.message);
      } finally {
        if (isMounted.current) setLoading(false);
        isFetchingRef.current = false;
      }

      prevAddressRef.current = address;
    };

    manageReferralLink();
  }, [
    dispatch,
    storeReferralLink,
    isReferrerLoaded,
    referrer,
    referrerUpdateCount,
  ]);

  const handleCopy = (link) => {
    if (!link) return;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch(() => setError("Failed to copy link to clipboard"));
  };

  return { referralLink, copied, error, handleCopy, loading };
};

export default useReferralLink;
