import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import blockchainPriceFeeds from "../../../../utils/priceFeedsByChainId";
import {
  currencyIcons,
  specialCurrencyLabels,
  getDefaultCurrencies,
} from "../../../../utils/currenciesUtils";
import logoIcon from "../../../../assets/img/logo-icon-dark.png";
import nPoints from "../../../../assets/img/nova-points.svg";
import swapIcon from "../../../../assets/img/swap-icon.svg";
import { useSelector, useDispatch } from "react-redux";
import { setDollarAmount } from "../../../../reducers/dollarSlice";
import { setCurrencyBalances } from "../../../../reducers/currencyBalancesSlice";
import {
  setSelectedCurrency,
  initializeCurrency,
} from "../../../../reducers/currencySlice";
import {
  updateBalance,
  updateBalanceInDollar,
} from "../../../../reducers/balanceSlice";
import {
  setEthAmount,
  setSnovaAmount,
  // setNovaPoints,
  setLastConnectedChainId,
} from "../../../../reducers/amountsSlice";
import useNovaPoints, {
  calculateNovaPoints,
} from "../../../../hooks/useNovaPoints";
import { useTranslation } from "react-i18next";
import {
  useDebouncedValue,
  truncateToDecimalPlace,
  formatValue,
  validateAndFormatInput,
} from "../../../../utils/helpers";
import { useBlockchain } from "../../../../hooks/useBlockchain";
import useBalanceData from "../../../../hooks/useBalanceData";
import { debounce } from "../../../../utils/debounce";
import ConnectButton from "./ConnectButton";
import "../banner.css";
import tokensByChainId from "../../../../utils/tokensByChainId";

import CurrencyOption from "./CurrencyOption";

const ethers = require("ethers");
const actualPricePerOneSNOVA = Number(process.env.REACT_APP_ACTUAL_PRICE);

export default function RatioCalculatorCrypto() {
  const { isConnected, chainId, address } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const dispatch = useDispatch();

  const ethAmount = useSelector((state) => state.amounts.ethAmount);
  const snovaAmount = useSelector((state) => state.amounts.snovaAmount);

  // const novaPoints = useSelector((state) => state.amounts.novaPoints);
  const lastConnectedChainId = useSelector(
    (state) => state.amounts.lastConnectedChainId
  );
  const dollarAmount = useSelector((state) => state.dollar.dollarAmount);

  const selectedCurrency = useSelector(
    (state) => state.currency.selectedCurrency
  );
  const currencyBalances = useSelector((state) => state.currencyBalances);
  const selectedFiat = useSelector((state) => state.currency.selectedFiat);
  const CurrencyAmount = useSelector((state) => state.balance.CurrencyAmount);
  const version = useSelector((state) => state.balance.version);
  const buyWithCard = useSelector((state) => state.buyWithCard.buyWithCard);

  const [maximumRoundSupplyInDollars, setMaximumRoundSupplyInDollars] =
    useState(288000);
  const totalTargetForRoundInDollars = useSelector(
    (state) => state.snovaData.totalTargetForRoundInDollars
  );
  const currentRoundSold = useSelector(
    (state) => state.snovaData.currentRoundSold
  );

  useEffect(() => {
    if (
      totalTargetForRoundInDollars !== null &&
      currentRoundSold !== null &&
      totalTargetForRoundInDollars !== 0 &&
      currentRoundSold !== 0 &&
      !isNaN(totalTargetForRoundInDollars) &&
      !isNaN(currentRoundSold)
    ) {
      const newMaximum = totalTargetForRoundInDollars - currentRoundSold;
      setMaximumRoundSupplyInDollars(newMaximum);
    }
  }, [totalTargetForRoundInDollars, currentRoundSold]);

  const [dropInner, setDropInner] = useState(false);
  const [inputSource, setInputSource] = useState("eth");

  const [isRestoring, setIsRestoring] = useState(true);

  const calculatedNovaPoints = useNovaPoints(parseFloat(dollarAmount));

  const dropdownRef = useRef(null);
  const ethAmountRef = useRef(ethAmount);
  const inputSourceRef = useRef(inputSource);

  useEffect(() => {
    ethAmountRef.current = ethAmount;
    inputSourceRef.current = inputSource;
  }, [ethAmount, inputSource]);

  const {
    normalizedPrice,
    prices,
    fetchNormalizedPrice,
    fetchAllPrices,
    fetchPriceForCurrency,
  } = useBlockchain({
    isConnected,
    chainId,
    walletProvider,
    selectedCurrency,
    selectedFiat,
    buyWithCard,
  });

  useEffect(() => {
    if (isConnected) {
      fetchCurrencyBalances();
    }
  }, [isConnected, selectedCurrency, chainId, prices]);

  const [currentNormalizedPrice, setCurrentNormalizedPrice] =
    useState(normalizedPrice);
  const debouncedEthAmount = useDebouncedValue(ethAmount, 300);
  const debouncedSnovaAmount = useDebouncedValue(snovaAmount, 300);
  const { t } = useTranslation();

  useEffect(() => {
    fetchNormalizedPrice().then((price) => setCurrentNormalizedPrice(price));
  }, [fetchNormalizedPrice]);

  useEffect(() => {
    if (inputSource === "eth" && debouncedEthAmount !== null) {
      calculateSnova(debouncedEthAmount);
    } else if (inputSource === "snova" && debouncedSnovaAmount !== null) {
      calculateEth(debouncedSnovaAmount);
    }
  }, [debouncedEthAmount, debouncedSnovaAmount, normalizedPrice, inputSource]);

  useEffect(() => {
    if (CurrencyAmount) {
      const amount = Number(CurrencyAmount);
      if (!isNaN(amount)) {
        dispatch(setEthAmount(amount));
        setInputSource("eth");
      }
    }
  }, [CurrencyAmount, version, dispatch]);

  useEffect(() => {
    if (isConnected) {
      handleConnectedState(chainId);
    } else {
      handleDisconnectedState();
    }
  }, [chainId, isConnected]);

  useEffect(() => {
    updateCurrencyOptions();
  }, [chainId, isConnected, selectedCurrency]);

  useEffect(() => {
    restorePreviousAmounts();
  }, []);

  useEffect(() => {
    if (isRestoring) {
      return;
    }
    saveAmountsToLocalStorage();
  }, [ethAmount, snovaAmount, inputSource]);

  useEffect(() => {
    recalculateValuesIfNeeded();
  }, [chainId, selectedCurrency, isConnected]);

  const { balance, balanceInDollar, fetchBalanceAndPrice } = useBalanceData({
    isConnected,
    walletProvider,
    chainId,
    selectedCurrency,
    address,
    fetchPriceForCurrency,
  });

  useEffect(() => {
    dispatch(updateBalance(balance));
    dispatch(updateBalanceInDollar(balanceInDollar));
  }, [balance, balanceInDollar, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      if (isConnected) {
        await fetchAllPrices();
        await fetchCurrencyBalances();
        await fetchBalanceAndPrice();
      }
      if (inputSource !== "snova") {
        const updatedPrice = await fetchPriceForCurrency(selectedCurrency);
        if (
          updatedPrice !== null &&
          updatedPrice !== currentNormalizedPrice &&
          inputSource === "eth"
        ) {
          setCurrentNormalizedPrice(updatedPrice);
          debouncedSetDollarAmount(ethAmountRef.current * updatedPrice);
          calculateSnova();
        }
      } else {
        const updatedPrice = await fetchPriceForCurrency(selectedCurrency);
        if (
          updatedPrice !== null &&
          updatedPrice !== currentNormalizedPrice &&
          inputSource === "snova"
        ) {
          setCurrentNormalizedPrice(updatedPrice);
          debouncedSetDollarAmount(ethAmountRef.current * updatedPrice);
          calculateEth();
        }
      }
      const updatedDollarAmount = parseFloat(dollarAmount);
      const updatedNovaPoints = calculateNovaPoints(updatedDollarAmount);
      // dispatch(setNovaPoints(updatedNovaPoints));
    };

    fetchData();
  }, [
    isConnected,
    selectedCurrency,
    chainId,
    fetchBalanceAndPrice,
    dollarAmount,
    dispatch,
  ]);

  const handleClickOutside = useCallback((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropInner(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const debouncedSetDollarAmount = useCallback(
    debounce((amount) => {
      dispatch(setDollarAmount(amount));
    }, 325),
    [dispatch]
  );

  const toggleInner = async (event) => {
    event.stopPropagation();
    setDropInner((prev) => !prev);

    if (!dropInner) {
      await fetchAllPrices();
      await fetchCurrencyBalances();
    }
  };

  const handleCurrencyChange = (currency, event) => {
    event.stopPropagation();
    dispatch(setSelectedCurrency(currency));
    setDropInner(false);
  };

  const fetchCurrencyBalances = async () => {
    if (!isConnected) {
      return;
    }

    try {
      const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const userAddress = await signer.getAddress();
      const balances = {};

      const fetchBalance = async (currency) => {
        if (["ETH", "BNB", "POL", "AVAX"].includes(currency)) {
          const balance = await ethersProvider.getBalance(userAddress);
          return ethers.utils.formatEther(balance);
        } else {
          const tokenInfo = tokensByChainId[chainId]?.[currency];
          if (tokenInfo && tokenInfo.address) {
            const contract = new ethers.Contract(
              tokenInfo.address,
              ["function balanceOf(address owner) view returns (uint256)"],
              signer
            );
            const balance = await contract.balanceOf(userAddress);
            return balance / 10 ** tokenInfo.decimals;
          } else {
            return null;
          }
        }
      };

      const currencies =
        chainId === 137
          ? ["POL", "USDT", "USDC", "DAI"]
          : Object.keys(
              blockchainPriceFeeds[chainId] || getDefaultCurrencies()
            );

      await Promise.all(
        currencies.map(async (currency) => {
          const balance = await fetchBalance(currency);
          if (balance !== null) {
            const price = prices[currency] || 0;
            const balanceInDollar = truncateToDecimalPlace(balance, 4) * price;
            balances[currency] = { balance, balanceInDollar };
          }
        })
      );

      dispatch(setCurrencyBalances(balances));
    } catch (error) {
      console.error("Error fetching balances:", error);
    }
  };

  const getCryptoCurrencyOptions = () => {
    let currenciesToShow = {};
    const selectedChainId = isConnected ? chainId : lastConnectedChainId;
    const numericChainId = Number(selectedChainId);

    if (numericChainId && blockchainPriceFeeds[numericChainId]) {
      if (numericChainId === 137) {
        const allowedCurrencies = ["POL", "USDT", "USDC", "DAI"];
        currenciesToShow = Object.keys(blockchainPriceFeeds["137"])
          .filter((currency) => allowedCurrencies.includes(currency))
          .reduce((obj, key) => {
            obj[key] = blockchainPriceFeeds["137"][key];
            return obj;
          }, {});
      } else {
        currenciesToShow = blockchainPriceFeeds[numericChainId];
      }
    } else {
      currenciesToShow = getDefaultCurrencies().reduce((obj, currency) => {
        obj[currency] = { address: null };
        return obj;
      }, {});
    }

    return Object.keys(currenciesToShow).map((currency) => {
      const displayCurrency = specialCurrencyLabels[currency] || currency;
      const balanceData = currencyBalances[currency] || {};
      return (
        <div
          key={currency}
          onClick={(e) => handleCurrencyChange(currency, e)}
          className={`send__asset-select css-f6epcl dropdown ${
            selectedCurrency === currency ? "active" : ""
          }`}
        >
          <CurrencyOption
            currency={currency}
            currencyName={displayCurrency}
            balance={balanceData.balance}
            balanceInDollar={balanceData.balanceInDollar}
            isConnected={isConnected}
          />
        </div>
      );
    });
  };

  const handleConnectedState = (chainId) => {
    if (!chainId) return;

    localStorage.setItem("lastConnectedChainId", chainId);
    dispatch(setLastConnectedChainId(chainId));

    dispatch(initializeCurrency({ chainId }));
  };

  const handleDisconnectedState = () => {
    const storedChainId = localStorage.getItem("lastConnectedChainId");
    if (storedChainId && !isConnected) {
      dispatch(setLastConnectedChainId(storedChainId));
      dispatch(initializeCurrency({ chainId: storedChainId }));
    }
  };

  const updateCurrencyOptions = () => {
    const effectiveChainId = isConnected ? chainId : lastConnectedChainId;
    if (!effectiveChainId) return;

    let currencies = blockchainPriceFeeds[effectiveChainId] || {};
    if (effectiveChainId === 137) {
      const allowedCurrencies = ["POL", "USDT", "USDC", "DAI"];
      currencies = Object.keys(currencies)
        .filter((currency) => allowedCurrencies.includes(currency))
        .reduce((obj, key) => {
          obj[key] = currencies[key];
          return obj;
        }, {});
    } else if (!Object.keys(currencies).length) {
      getDefaultCurrencies().forEach((currency) => {
        currencies[currency] = { address: null };
      });
    }

    const previousSelectedCurrency = localStorage.getItem("selectedCurrency");
    if (!Object.keys(currencies).includes(selectedCurrency)) {
      const firstCurrency = Object.keys(currencies)[0];
      dispatch(setSelectedCurrency(firstCurrency));
    } else if (!previousSelectedCurrency) {
      const firstCurrency = Object.keys(currencies)[0];
      dispatch(setSelectedCurrency(firstCurrency));
    }
  };

  const restorePreviousAmounts = () => {
    try {
      const savedEthAmountString = localStorage.getItem("ethAmount");
      const savedSnovaAmountString = localStorage.getItem("snovaAmount");
      const savedInputSource = localStorage.getItem("inputSource");

      if (
        savedEthAmountString !== null &&
        !isNaN(Number(savedEthAmountString)) &&
        savedInputSource === "eth"
      ) {
        const savedEthAmount = Number(savedEthAmountString);
        dispatch(setEthAmount(savedEthAmount));
        setInputSource("eth");
      }
      if (
        savedSnovaAmountString !== null &&
        !isNaN(Number(savedSnovaAmountString)) &&
        savedInputSource === "snova"
      ) {
        const savedSnovaAmount = Number(savedSnovaAmountString);
        dispatch(setSnovaAmount(savedSnovaAmount));
        setInputSource("snova");
      }
    } catch (error) {
      console.error("Error in restorePreviousAmounts:", error);
    } finally {
      setIsRestoring(false);
    }
  };

  const saveAmountsToLocalStorage = () => {
    if (ethAmount !== null && ethAmount !== undefined && !isNaN(ethAmount)) {
      localStorage.setItem("ethAmount", ethAmount);
    } else {
      localStorage.removeItem("ethAmount");
    }
    if (
      snovaAmount !== null &&
      snovaAmount !== undefined &&
      !isNaN(snovaAmount)
    ) {
      localStorage.setItem("snovaAmount", snovaAmount);
    } else {
      localStorage.removeItem("snovaAmount");
    }
    if (inputSource) {
      localStorage.setItem("inputSource", inputSource);
    }
  };

  const recalculateValuesIfNeeded = () => {
    const savedInputSource = localStorage.getItem("inputSource");
    if (savedInputSource === "eth" && ethAmount) {
      calculateSnova(ethAmount);
    } else if (savedInputSource === "snova" && snovaAmount) {
      calculateEth(snovaAmount);
    }
  };

  const calculateSnova = useCallback(
    async (ethValue) => {
      const ethAmountNumber = parseFloat(ethValue);
      if (inputSource !== "eth") {
        return;
      }
      if (typeof ethAmountNumber !== "number" || isNaN(ethAmountNumber)) {
        // console.error("Invalid ethValue:", ethValue);
        return;
      }
      if (typeof normalizedPrice !== "number" || isNaN(normalizedPrice)) {
        // console.error("Invalid normalizedPrice:", normalizedPrice);
        return;
      }
      try {
        const dollarAmount = ethAmountNumber * normalizedPrice;
        if (isNaN(dollarAmount)) {
          console.error("Invalid dollarAmount:", dollarAmount);
          return;
        }
        dispatch(setDollarAmount(dollarAmount));
        const snovaValue = formatValue(
          ethAmountNumber * (normalizedPrice / actualPricePerOneSNOVA),
          3
        );
        if (isNaN(snovaValue)) {
          console.error("Invalid snovaValue:", snovaValue);
          return;
        }
        if (snovaValue * actualPricePerOneSNOVA > maximumRoundSupplyInDollars) {
          const adjustedEthAmount = formatValue(
            maximumRoundSupplyInDollars / normalizedPrice,
            4
          );
          dispatch(setEthAmount(Number(adjustedEthAmount)));
          const adjustedSnovaAmount = formatValue(
            maximumRoundSupplyInDollars / actualPricePerOneSNOVA,
            0
          );
          dispatch(setSnovaAmount(Number(adjustedSnovaAmount)));
        } else {
          dispatch(setSnovaAmount(Number(snovaValue)));
        }
      } catch (error) {
        console.error("Error calculating SNOVA:", error);
      }
    },
    [normalizedPrice, inputSource, dispatch, maximumRoundSupplyInDollars]
  );

  const calculateEth = useCallback(
    async (snovaValue) => {
      const snovaAmountNumber = parseFloat(snovaValue);
      if (inputSource !== "snova") {
        return;
      }
      if (typeof snovaAmountNumber !== "number" || isNaN(snovaAmountNumber)) {
        // console.error("Invalid snovaValue:", snovaValue);
        return;
      }
      if (typeof normalizedPrice !== "number" || isNaN(normalizedPrice)) {
        // console.error("Invalid normalizedPrice:", normalizedPrice);
        return;
      }
      try {
        const dollarAmount =
          (snovaAmountNumber * 100 * actualPricePerOneSNOVA * 100) / 10000;
        if (isNaN(dollarAmount)) {
          console.error("Invalid dollarAmount:", dollarAmount);
          return;
        }
        dispatch(setDollarAmount(dollarAmount));

        const finalValue = normalizedPrice / actualPricePerOneSNOVA;

        if (isNaN(finalValue) || isNaN(snovaAmountNumber)) {
          throw new Error("Calculation resulted in NaN");
        }

        const ethValue = snovaAmountNumber / finalValue;

        let truncatedEthValue;
        if (ethValue * normalizedPrice > maximumRoundSupplyInDollars) {
          truncatedEthValue = formatValue(
            maximumRoundSupplyInDollars / normalizedPrice,
            4
          );

          if (truncatedEthValue === undefined) {
            throw new Error("formatValue returned undefined");
          }

          dispatch(setEthAmount(Number(truncatedEthValue)));
          const adjustedSnovaAmount = formatValue(
            maximumRoundSupplyInDollars / actualPricePerOneSNOVA,
            0
          );
          dispatch(setSnovaAmount(Number(adjustedSnovaAmount)));
        } else {
          truncatedEthValue = formatValue(ethValue, 4);

          if (truncatedEthValue === undefined) {
            throw new Error("formatValue returned undefined");
          }

          dispatch(setEthAmount(Number(truncatedEthValue)));
        }
      } catch (error) {
        console.error("Error calculating ETH:", error);
      }
    },
    [normalizedPrice, inputSource, dispatch, maximumRoundSupplyInDollars]
  );

  const handleEthAmountChange = (e) => {
    const rawInput = e.target.value;
    const formattedValue = validateAndFormatInput(rawInput, ethAmount, 4);
    dispatch(setEthAmount(formattedValue));
    setInputSource("eth");
    localStorage.setItem("inputSource", "eth");
    if (formattedValue) {
      calculateSnova(parseFloat(formattedValue));
    } else {
      dispatch(setSnovaAmount(0));
      dispatch(setDollarAmount(0));
    }
  };

  const handleSnovaAmountChange = (e) => {
    const rawInput = e.target.value;
    const formattedValue = validateAndFormatInput(rawInput, snovaAmount, 3);
    dispatch(setSnovaAmount(formattedValue));
    setInputSource("snova");
    localStorage.setItem("inputSource", "snova");
    if (formattedValue) {
      calculateEth(parseFloat(formattedValue));
    } else {
      dispatch(setEthAmount(0));
      dispatch(setDollarAmount(0));
    }
  };

  function truncateNumber(number, decimalPlaces) {
    const factor = Math.pow(10, decimalPlaces);
    return Math.floor(number * factor) / factor;
  }

  return (
    <div>
      <div className="css-1lekzkb">
        <div className="css-1p1m4ay">
          <p className="MuiTypography-root MuiTypography-body1 text-[14px] css-6hkpqo">
            {t("homePage.banner.balance")}
          </p>
          <ConnectButton
            buttonText={t("homePage.banner.connectYourWallet")}
            className="MuiTypography-root MuiTypography-body1 css-1enqu04"
            balance={balance}
            balanceInDollar={balanceInDollar}
          />
        </div>
      </div>
      <div className="flex flex-col laptop:flex-row justify-between gap-[4px] relative z-index-11">
        <style>
          {`
          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          input[type="number"] {
            -moz-appearance: textfield; /* Firefox */
          }
        `}
        </style>
        <div className="css-1c5id9a">
          <div
            ref={dropdownRef}
            onClick={toggleInner}
            className="css-70qvj9"
            style={{ cursor: "pointer" }}
          >
            <img
              src={
                typeof currencyIcons[selectedCurrency] === "string"
                  ? currencyIcons[selectedCurrency]
                  : ""
              }
              alt={specialCurrencyLabels[selectedCurrency] || selectedCurrency}
              className="css-vqypjx"
              style={{
                width: "24px",
                height: "24px",
                objectFit: "cover",
              }}
            />
            <p className="text-[14px] text-[#F5F5F4] mx-[8px] font-[500]">
              {specialCurrencyLabels[selectedCurrency] || selectedCurrency}
            </p>
            <div
              className="_arrow_1oajo_1"
              style={{
                transform: dropInner ? "rotate(180deg)" : "rotate(0deg)",
                transition: "all 0.3s ease",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8 10L12 5.73613L4 5.73613L8 10Z"
                  fill="#A9A29D"
                />
              </svg>
            </div>
            {dropInner && (
              <div className="css-1r69ko0">{getCryptoCurrencyOptions()}</div>
            )}
          </div>
          <input
            type="text"
            placeholder="0"
            value={ethAmount}
            onChange={handleEthAmountChange}
            className="css-kxgh99"
          />
          <div className="dollar-text">${formatValue(dollarAmount, 2)}</div>
        </div>
        <div className="css-1c5id9a">
          <div className="css-70qvj9">
            <img src={logoIcon} className="buy-snova-icon" alt="Snova icon" />
            <p className="MuiTypography-root MuiTypography-body1 css-eikm1t">
              SNOVA
            </p>
          </div>
          <input
            type="text"
            placeholder="0"
            value={snovaAmount}
            onChange={handleSnovaAmountChange}
            className="css-kxgh99"
          />
          <span
            className="dollar-text orange text-accent text-[20px] font-medium flex items-center gap-1 leading-[25px] mb-[-2px] sm:text-[16px]"
            translate="no"
          >
            <img
              src={nPoints}
              style={{ height: "25px", width: "20px", marginRight: "4px" }}
              alt="Nova points icon"
            />
            +{formatValue(calculatedNovaPoints, 0)}{" "}
            {t("homePage.novaBox.novaPoints")}
          </span>
        </div>
        <div className="css-im6gv3 for-mblonly ">
          <img
            src={swapIcon}
            style={{ height: "20px", width: "20px" }}
            alt="Swap icon"
          />
        </div>
      </div>
    </div>
  );
}
