import React from "react";
import "./AuditSecton.css";
import aiFeaturesBg from "../../../assets/img/ai-features-glow.webp";
import aiFeaturesBg2 from "../../../assets/img/ai-features-glow-2.png";
import aiToolsFade from "../../../assets/img/ai-tools-fade.png";

import walletTracker from "../../../assets/img/ai-wallet-tracker.webp";
import insightAnalyzer from "../../../assets/img/ai-insight-analyzer.webp";
import faqAssistant from "../../../assets/img/ai-faq-assistant.webp";
import tokenomicsScanner from "../../../assets/img/ai-tokenomics-scanner.webp";
import marketPredictor from "../../../assets/img/ai-market-predictor.webp";
import { useTranslation } from "react-i18next";
const AuditSecton = () => {
  const { t } = useTranslation();
  return (
    <div className="relative">
      {/*
      <img
        className="absolute w-[487px] right-0 h-[362px] right-[-100px] top-[-100px] md:w-[487px] md:h-[362px] md:right-[-100px] md:top-[-100px] lg:w-[650px] lg:h-[483px] lg:right-[-100px] lg:top-[-200px] xl:w-[813px] xl:h-[604px] xl:right-[-100px] xl:top-[-250px] 2xl:w-[813px] 2xl:h-[604px] 2xl:right-[-100px] 2xl:top-[-250px]"
        src={aiToolsFade}
        alt="orderly-network-main-brand-background"
      />
    */}
      <div className="max-width padding-block">
        <div className="   SectionHeaderV2_SectionHeaderV2__UELE4 home_sectionHeader__suL52">
          <div className="mb-5 text-center SectionHeaderV2_sectionHeaderSubtitle__eUmZ_">
            {t("homePage.auditSection.intelligentInvestingUnleashed")}
          </div>
        </div>
        <div
          id="cards-grid"
          className="cardgrid-wrapper section-padding relative"
        >
          <div className="graphic-wrapper">
            <div className="graphic-inner">
              <svg
                className="circle-section"
                width={604}
                height={604}
                fill="none"
                viewBox="0 0 604 604"
              >
                <circle
                  className="circle"
                  cy={302}
                  r={300}
                  strokeDasharray="0.1 20"
                  cx={302}
                  stroke="#fc6432"
                  strokeWidth={3}
                  strokeLinecap="round"
                  id="dottedcircle"
                  style={{
                    translate: "none",
                    rotate: "none",
                    scale: "none",
                    transformOrigin: "0px 0px",
                  }}
                  data-svg-origin="302 302"
                  transform="matrix(0.90437,0.42675,-0.42675,0.90437,157.75876,-99.99824)"
                />
                <circle
                  className="circle"
                  cy={280}
                  r={280}
                  cx={280}
                  stroke="url(#strokegradient2)"
                  strokeLinecap="round"
                  transform="matrix(0.90437,0.42675,0.42675,-0.90437,-70.7136,435.7336)"
                  id="solidcircle"
                  style={{
                    strokeDashoffset: 0,
                    strokeDasharray: "1696.74px, 62.6526px",
                    translate: "none",
                    rotate: "none",
                    scale: "none",
                    transformOrigin: "0px 0px",
                  }}
                  data-svg-origin="280 280"
                />
                <defs>
                  <linearGradient id="strokegradient">
                    <stop stopColor="#D7A7FF" />
                    <stop stopColor="#D7A7FF" offset={1} />
                  </linearGradient>
                </defs>
              </svg>
              <div className="div-block-150">
                <img
                  src={aiFeaturesBg}
                  loading="lazy"
                  srcSet={aiFeaturesBg}
                  alt="AI features"
                  sizes="(max-width: 479px) 100vw, 604px"
                  className="graphic_glow"
                />
              </div>
            </div>
          </div>
          <div className="cardgrid_glow-wrapper">
            <img
              src={aiFeaturesBg}
              loading="lazy"
              sizes="(max-width: 479px) 92vw, (max-width: 767px) 94vw, (max-width: 881px) 90vw, 793px"
              srcSet={aiFeaturesBg}
              alt="AI features"
              className="image-30"
            />
          </div>
          <div className="cardgrid">
            <div className="cardgrid-row">
              <div className="cardgrid-item">
                <div className="card-regular">
                  <div className="icon-1x1-medium w-embed">
                    <img
                      src={walletTracker}
                      alt="Wallet tracker icon"
                      loading="lazy"
                    />
                  </div>
                  <h4 className="text-color-primary">
                    {t("homePage.auditSection.aiWalletTracker")}
                  </h4>
                  <div className="text-size-regular text-color-secondary">
                    {t("homePage.auditSection.aiWalletTrackerDescription")}
                  </div>
                </div>
              </div>
              <div className="cardgrid-item">
                <div className="card-regular">
                  <div className="icon-1x1-medium w-embed">
                    <img
                      src={insightAnalyzer}
                      alt="Insight analyzer icon"
                      loading="lazy"
                    />
                  </div>
                  <h4 className="text-color-primary">
                    {t("homePage.auditSection.aiInsightAnalyzer")}
                  </h4>
                  <div className="text-size-regular text-color-secondary">
                    {t("homePage.auditSection.aiInsightAnalyzerDescription")}
                  </div>
                </div>
              </div>
            </div>
            <div className="cardgrid-row">
              <div className="card-highlight">
                <div className="glow-wraper">
                  <img
                    src={aiFeaturesBg2}
                    loading="lazy"
                    sizes="(max-width: 767px) 100vw, (max-width: 991px) 90vw, 40vw"
                    alt="AI features"
                    className="image-26"
                  />
                </div>
                <div className="card-highlight_content">
                  <h4 className="text-color-primary-invert margin-none">
                    <img
                      src={marketPredictor}
                      className="predictor-icon"
                      alt="Predictor icon"
                      loading="lazy"
                    />
                    {t("homePage.auditSection.aiMarketPredictor")}
                  </h4>
                  <div className="text-size-regular text-color-secondary">
                    {t("homePage.auditSection.aiMarketPredictorDescription")}
                  </div>
                  <a
                    href="https://stacknova-ai.gitbook.io/stacknova-ai/stacknova-ecosystem/five-pillars-of-ai"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flat-toggle box-text bx-2 box-btn corner-box flex items-center"
                  >
                    <span className="toggle-title">
                      {t("homePage.auditSection.learnMore")}
                      <span className="sr-only">
                        about the Five Pillars of AI
                      </span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="cardgrid-row">
              <div className="cardgrid-item">
                <div className="card-regular">
                  <div className="icon-1x1-medium w-embed">
                    <img
                      src={faqAssistant}
                      alt="Faq assistant icon"
                      loading="lazy"
                    />
                  </div>
                  <h4 className="text-color-primary">
                    {t("homePage.auditSection.aiFAQAssistant")}
                  </h4>
                  <div className="text-size-regular text-color-secondary">
                    {t("homePage.auditSection.aiFAQAssistantDescription")}
                  </div>
                </div>
              </div>
              <div className="cardgrid-item">
                <div className="card-regular">
                  <div className="icon-1x1-medium w-embed">
                    <img
                      src={tokenomicsScanner}
                      alt="Tokenomics scanner icon"
                      loading="lazy"
                    />
                  </div>
                  <h4 className="text-color-primary">
                    {t("homePage.auditSection.aiTokenomicsScanner")}
                  </h4>
                  <div className="text-size-regular text-color-secondary">
                    {t("homePage.auditSection.aiTokenomicsScannerDescription")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuditSecton;
