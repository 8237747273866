/**
 * Safely parses a JSON string from environment variables.
 * @param {string} jsonString - The JSON string to parse.
 * @param {string} varName - The name of the environment variable (for error messages).
 * @returns {object} - The parsed JSON object or an empty object if parsing fails.
 */
const parseJson = (jsonString, varName) => {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    return {}; // Return an empty object to prevent the app from crashing
  }
};

// Parse the Registry Addresses
const registryAddressesByChainId = parseJson(
  process.env.REACT_APP_REGISTRY_ADDRESSES_BY_CHAIN_ID,
  "REACT_APP_REGISTRY_ADDRESSES_BY_CHAIN_ID"
);

// Parse the Presale Addresses
const presaleAddressesByChainId = parseJson(
  process.env.REACT_APP_PRESALE_ADDRESSES_BY_CHAIN_ID,
  "REACT_APP_PRESALE_ADDRESSES_BY_CHAIN_ID"
);

// Export the configurations
export { registryAddressesByChainId, presaleAddressesByChainId };
