import { useDispatch, useSelector } from "react-redux";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { setBlur2 } from "../reducers/blurSlice";
import {
  setModal2,
  setModal3,
  setModal5,
  setModal6,
} from "../reducers/modalSlice";
import { setAllRewards } from "../reducers/referralRewardsSlice";
import tokensByChainId from "../utils/tokensByChainId";
import { getRegistryAddress } from "../utils/getAddresses";
import registryAbi from "../utils/abis/registryAbi.json";
const { Contract, ethers } = require("ethers");

let ABI = registryAbi;

const useClaimReferralRewards = () => {
  const dispatch = useDispatch();
  const { address, isConnected, chainId } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const allRewards = useSelector((state) => state.referralRewards.allRewards);

  const claimRewards = async (rewardsChainId, claimedData) => {
    const tokensConfig = tokensByChainId[rewardsChainId];
    const tokens = Object.values(tokensConfig).map((token) => token.address);
    const registryAddress = getRegistryAddress(rewardsChainId);

    dispatch(setBlur2(true));
    if (!isConnected) {
      console.error("User disconnected");
      throw new Error("User disconnected");
    }

    if (chainId !== rewardsChainId) {
      try {
        const formattedChainId = ethers.utils.hexStripZeros(
          ethers.utils.hexlify(rewardsChainId)
        );
        await walletProvider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: formattedChainId }],
        });
      } catch (switchError) {
        console.error("Failed to switch network:", switchError);
        dispatch(
          setModal5({
            isOpen: true,
            data: { error: "Please switch to the correct network." },
          })
        );
        dispatch(setBlur2(false));
        return;
      }
    }

    const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
    const signer = await ethersProvider.getSigner();
    const contract = new Contract(registryAddress, ABI, signer);

    try {
      const transactionResponse = await contract.claimRef(tokens);
      const receipt = await transactionResponse.wait();

      if (receipt.status === 1) {
        const claimedEvents = receipt.events
          .filter((event) => event.event === "ReferralRewardsClaimed")
          .map((event) => ({
            ref: event.args.ref,
            token: event.args.token,
            amount: ethers.utils.formatUnits(event.args.amount, 18),
          }));

        const updatedRewards = JSON.parse(JSON.stringify(allRewards));

        Object.keys(updatedRewards[rewardsChainId]).forEach((currency) => {
          updatedRewards[rewardsChainId][currency] = {
            amount: "0",
            usdValue: "0",
          };
        });

        dispatch(setAllRewards(updatedRewards));
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/updateReferralLinkRewards`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ address, network: String(rewardsChainId) }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to update referral link rewards");
        }

        dispatch(
          setModal3({
            isOpen: true,
            data: {
              network: rewardsChainId,
              totalNetworkReward: claimedData.totalNetworkReward,
              amounts: claimedData.amounts,
              transactionHash: receipt.transactionHash,
            },
          })
        );
      }
    } catch (error) {
      if (
        error.code === 4001 ||
        (error.code === undefined && error.message.includes("User rejected")) ||
        error.message.includes("rejected") ||
        error.message.includes("declined") ||
        error.message.includes("cancelled")
      ) {
        dispatch(
          setModal5({
            isOpen: true,
            data: { error: "Transaction was declined by the user." },
          })
        );
      } else if (
        (error.code === -32000 &&
          (error.message.includes("intrinsic gas too low") ||
            error.message.includes("gas required exceeds allowance") ||
            error.message.includes("out of gas") ||
            error.message.includes("exceeds gas limit"))) ||
        error.message.includes("gas too low")
      ) {
        dispatch(setModal2({ isOpen: true, data: { error: error.message } }));
      } else {
        dispatch(setModal6({ isOpen: true, data: { error: error.message } }));
      }
    } finally {
      dispatch(setBlur2(false));
    }
  };

  return { claimRewards };
};

export default useClaimReferralRewards;
