// src/hooks/useFetchAllReferralRewards.js

import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAllRewards } from "../reducers/referralRewardsSlice";
import { setReferralCount } from "../reducers/referralCountSlice";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { debounce } from "../utils/debounce";
import tokensByChainId from "../utils/tokensByChainId";

const useFetchAllReferralRewards = () => {
  const dispatch = useDispatch();
  const { address } = useWeb3ModalAccount();
  const allRewards = useSelector((state) => state.referralRewards.allRewards);

  // const fetchPrices = async () => {
  //   const tokenMap = {
  //     ETH: "ethereum",
  //     BNB: "binancecoin",
  //     MATIC: "polygon",
  //     AVAX: "avalanche-2",
  //     ARB: "arbitrum",
  //     OP: "optimism",
  //     ZK: "zk-rollups",
  //   };

  //   let prices = {};
  //   let fetchSuccess = false;
  //   const pricesFromAPI = process.env.REACT_APP_PRICES_API;

  //   try {
  //     const response = await fetch(pricesFromAPI);
  //     if (response.ok) {
  //       const cryptoComparePrices = await response.json();

  //       prices = Object.keys(tokenMap).reduce((acc, key) => {
  //         acc[tokenMap[key]] = { usd: cryptoComparePrices[key].USD };
  //         return acc;
  //       }, {});

  //       fetchSuccess = true;
  //     } else {
  //       console.error(
  //         "CryptoCompare API call failed with status:",
  //         response.status
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error fetching from CryptoCompare:", error.message);
  //   }

  //   if (!fetchSuccess) {
  //     throw new Error("Failed to fetch prices from both APIs");
  //   }

  //   return prices;
  // };

  const fetchPrices = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/getPricesHandler`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const pricesData = await response.json();

        const tokenMap = {
          ETH: "ethereum",
          BNB: "binancecoin",
          POL: "polygon",
          AVAX: "avalanche-2",
          ARB: "arbitrum",
          OP: "optimism",
          ZK: "zk-rollups",
        };

        const prices = {};

        Object.keys(tokenMap).forEach((key) => {
          const tokenName = tokenMap[key];
          if (pricesData[key]) {
            prices[tokenName] = { usd: pricesData[key] };
          } else {
            prices[tokenName] = { usd: 0 };
          }
        });

        prices["USDT"] = { usd: pricesData.USDT || 1 };
        prices["USDC"] = { usd: pricesData.USDC || 1 };
        prices["DAI"] = { usd: pricesData.DAI || 1 };

        return prices;
      } else {
        const errorData = await response.json();
        console.error("Error fetching prices:", errorData.error);
        throw new Error("Failed to fetch prices");
      }
    } catch (error) {
      console.error("Error fetching prices:", error.message);
      throw error;
    }
  };

  const fetchReferralRewards = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/getReferralRewards`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ walletAddress: address }),
        }
      );

      if (response.ok) {
        const data = await response.json();

        if (!data.rewardsFound) {
          // console.log("No referral rewards found.");
          return;
        }

        const { pending_rewards, active_referrals } = data.referralLink;

        const prices = await fetchPrices();

        // if (prices["MATIC"]) {
        //   prices["POL"] = prices["MATIC"];
        //   delete prices["MATIC"];
        // }

        const tokenMap = {
          ETH: "ethereum",
          BNB: "binancecoin",
          POL: "polygon",
          AVAX: "avalanche-2",
          ARB: "arbitrum",
          OP: "optimism",
          ZK: "zk-rollups",
        };

        let formattedRewards = {};
        Object.keys(tokensByChainId).forEach((chainId) => {
          formattedRewards[chainId] = {};
          Object.keys(tokensByChainId[chainId]).forEach((currency) => {
            const token = tokensByChainId[chainId][currency];
            const price = ["USDT", "USDC", "DAI"].includes(currency)
              ? 1
              : prices[tokenMap[currency.toUpperCase()]]?.usd || 0;

            const reward = pending_rewards
              .find((reward) => reward.network === chainId)
              ?.currencies.find((curr) => curr.currency === currency);

            if (reward) {
              const usdValue = reward.amount * price;
              formattedRewards[chainId][currency] = {
                amount: reward.amount.toString(),
                usdValue: usdValue.toString(),
              };
            } else {
              formattedRewards[chainId][currency] = {
                amount: "0",
                usdValue: "0",
              };
            }
          });
        });

        dispatch(setAllRewards(formattedRewards));

        const activeReferralCount = active_referrals.length;
        dispatch(setReferralCount(activeReferralCount));
      } else {
        const errorData = await response.json();
        console.error("Error fetching referral rewards:", errorData.error);
      }
    } catch (error) {
      console.error("Error fetching referral rewards:", error.message);
    }
  };

  const debouncedFetchReferralRewards = useCallback(
    debounce(fetchReferralRewards, 300),
    [address, dispatch]
  );

  useEffect(() => {
    if (!address) return;
    debouncedFetchReferralRewards();
  }, [address, debouncedFetchReferralRewards]);

  return { allRewards, fetchReferralRewards };
};

export default useFetchAllReferralRewards;
