export const ExclamationIcon = () => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 20 36"
    width={20}
  >
    <defs>
      <style>{`.st0  { fill: #fff; stroke-width: 0; }`}</style>
    </defs>
    <path
      className="st0"
      d="M18.7,13.9v-2.4h-1.2v-1.2h-1.2v-1.2h-2.4v-1.2H5.8v1.2h-2.4v1.2h-1.2v1.2H1v2.4H-.1v8.2h1.2v2.4h1.2v1.2h1.2v1.2h2.4v1.2h8.2v-1.2h2.4v-1.2h1.2v-1.2h1.2v-2.4h1.2v-8.2h-1.4ZM10.9,22.7h-1.9v-2h1.9v2ZM10.9,19.6h-1.9v-6.3h1.9v6.3Z"
    />
  </svg>
);
