import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Referral from "../pages/Referral";
import Profile from "../pages/Dashboard";
import IntercomInitializer from "../components/IntercomInitializer";
import useScrollRestoration from "../hooks/useScrollRestoration";
import ScrollPositionSaver from "./ScrollPositionSaver";

const ScrollRestorationWrapper = ({ children }) => {
  useScrollRestoration();
  return children;
};

export default function Router() {
  return (
    <BrowserRouter>
      <ScrollRestorationWrapper>
        <ScrollPositionSaver />
        <IntercomInitializer />
        <Routes>
          <Route index path="/" exact element={<Home />} />
          <Route path="referral" element={<Referral />} />
          <Route path="dashboard" element={<Profile />} />
        </Routes>
      </ScrollRestorationWrapper>
    </BrowserRouter>
  );
}
