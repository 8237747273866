import {
  registryAddressesByChainId,
  presaleAddressesByChainId,
} from "./addresses/config";

/**
 * Retrieves a registry address by chain ID.
 * @param {number|string} chainId - The chain ID.
 * @returns {string|null} - The corresponding registry address or null if not found.
 */
export const getRegistryAddress = (chainId) => {
  const address = registryAddressesByChainId[chainId];
  if (!address) {
    return null;
  }
  return address;
};

/**
 * Retrieves a presale address by chain ID.
 * @param {number|string} chainId - The chain ID.
 * @returns {string|null} - The corresponding presale address or null if not found.
 */
export const getPresaleAddress = (chainId) => {
  const address = presaleAddressesByChainId[chainId];
  if (!address) {
    return null;
  }
  return address;
};
