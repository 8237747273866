import { useDispatch } from "react-redux";
import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { setBlur } from "../reducers/blurSlice";
import tokensByChainId from "../utils/tokensByChainId";
import { getPresaleAddress } from "../utils/getAddresses";
import erc20Abi from "../utils/abis/erc20Abi.json";
const { Contract, ethers } = require("ethers");

const ERC20_ABI = erc20Abi;

const useApprove = () => {
  const dispatch = useDispatch();
  const { address, chainId } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const presaleAddress = getPresaleAddress(chainId);

  const checkAndApproveToken = async (currency, amount) => {
    dispatch(setBlur(true));

    try {
      if (["ETH", "BNB", "MATIC", "AVAX"].includes(currency)) return true;

      const tokenAddresses = tokensByChainId[chainId] || {};
      const tokenAddress = tokenAddresses[currency]?.address;

      if (!tokenAddress) return;

      const ethersProvider = new ethers.providers.Web3Provider(walletProvider);
      const signer = await ethersProvider.getSigner();
      const tokenContract = new Contract(tokenAddress, ERC20_ABI, signer);

      const allowance = await tokenContract.allowance(address, presaleAddress);
      if (allowance.lt(amount)) {
        const tx = await tokenContract.approve(presaleAddress, amount);
        await tx.wait();
      }
      return true;
    } catch (error) {
      throw error;
    } finally {
      dispatch(setBlur(false));
    }
  };

  return { checkAndApproveToken };
};

export default useApprove;
